import { VStack, Image, Text, Box } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const HomeRowCard = (props) => {
    return (
        <Box
            w={'100%'}
            as={Link}
            to={`/product/1`}
            display={'flex'}
            flexDirection={'column'}
            minH={'500px'}
            _hover={
                {
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                }
            }
            justifyContent={'space-between'} alignItems={'center'}>

            <Box
                backgroundImage={props.item.imgUrl}
                backgroundSize={'cover'}
                backgroundPosition={'center'}
                backgroundRepeat={'no-repeat'}
                h={'75%'}
                w={'100%'}
            />
            <VStack h={'25%'} w={'100%'}
                justifyContent={'center'} alignItems={'center'}>
                <Text
                    color={'#989898'}
                    fontSize={'xl'}>{props.item.name}</Text>
                <Text fontSize={'x-large'} fontWeight={'700'}
                    color={'#000000'}
                >$ {props.item.Price}</Text>
            </VStack>
        </Box>
    )
}

export default HomeRowCard