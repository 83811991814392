import { Flex, Textarea, VStack, Text, FormLabel, Input, Box, HStack, FormControl } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { FaClock, FaLocationDot } from 'react-icons/fa6';
import { useContacts } from '../../providers/ContactProvider';
import { toast } from 'react-toastify';
import { MdOutlineAccessTimeFilled } from 'react-icons/md';
import { FaPhoneAlt } from 'react-icons/fa';
import OutlinedButton from '../common/OutlinedButton';
import PlaceSale from '../common/PlaceSale';

const Contact = () => {
    const { addContact } = useContacts();

    const [error, setError] = useState();

    const [info, setinfo] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            message: "",
        },
        onSubmit: async (values) => {
            setinfo("Adding...");
            var res = await addContact(values.name, values.email, values.phone, values.message);
            setinfo(null);
            if (res === "err") {
                setError("Error Adding Contact !");
            } else {
                values.name = "";
                values.email = "";
                values.phone = "";
                values.message = "";
                toast.success("Thanks for your message!. We will get back to you soon");
            }
        }

    });

    return (
        <Flex
            minH='100vh'
            width={'100vw'}
            flexDirection={'column'}
            justifyContent={'space-between'} alignItems={'center'}
            bg={'#FFFFFF'}
        >
            <Box
                bgImage='./contact_banner.png'
                bgSize='cover'
                bgPosition='center'
                w='100%'
                h={'45vh'} />

            <VStack
                w={'85%'}
                my={10}
                justifyContent={'space-between'} alignItems={'center'}>
                <VStack
                    w={'70%'}
                    justifyContent={'center'}
                    gap={3}
                    alignItems={'center'}
                >
                    <Text
                        fontSize={'3xl'}
                        fontWeight={700}
                        textAlign={'center'}
                        color={'#000000'}
                    >
                        Get In Touch With Us
                    </Text>
                    <Text fontSize={'lg'}
                        textAlign={'center'}
                        color={'#000000'}
                    >
                        For More Information About Our Product & Services. Please Feel Free To Drop Us An Email. Our Staff Always Be There To Help You Out. Do Not Hesitate!
                    </Text>
                </VStack>
                <HStack
                    py={5}
                    w='5xl'
                    justifyContent={'space-between'}
                    alignItems={'start'}
                    spacing={10}

                >
                    <VStack
                        w='100%'
                        justifyContent={'start'}
                        alignItems={'start'}
                    >
                        <VStack
                            justifyContent={'start'}
                            w={'100%'}
                            alignItems={'start'}
                            spacing='8'
                        >
                            <Flex
                                justifyContent={'start'}
                                width={'100%'}
                                gap={4}
                            >
                                <FaLocationDot color='#000000' size={30} />
                                <VStack justifyContent={'flex-start'} alignItems={'flex-start'}>
                                    <Text color={'#000000'} fontSize={'2xl'} fontWeight={600}>
                                        Address
                                    </Text>
                                    <Text fontSize={'lg'} color={'#000000'}>
                                        236 5th SE Avenue, New York NY10000, United States
                                    </Text>
                                </VStack>

                            </Flex>
                            <Flex gap={4}
                                justifyContent={'start'}
                                w='100%'
                            >
                                <FaPhoneAlt color='#000000' size={30} />
                                <VStack justifyContent={'flex-start'} alignItems={'flex-start'}>
                                    <Text color={'#000000'} fontSize={'2xl'} fontWeight={600}>
                                        Phone
                                    </Text>
                                    <Text fontSize={'lg'} color={'#000000'}>
                                        Mobile: +(84) 546-6789 Hotline: +(84) 456-6789
                                    </Text>
                                </VStack>

                            </Flex>
                            <Flex gap={4}>
                                <FaClock color='#000000' size={30} />
                                <VStack justifyContent={'flex-start'} alignItems={'flex-start'}>
                                    <Text
                                        fontSize={'2xl'}
                                        color={'#000000'}
                                        fontWeight={600}>
                                        Address
                                    </Text>
                                    <Text fontSize={'lg'} color={'#000000'}>
                                        236 5th SE Avenue, New York NY10000, United States
                                    </Text>
                                </VStack>

                            </Flex>
                        </VStack>

                        <Flex h={'300px'} w={'100%'} bg={'#D9D9D9'} justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Text textAlign={'center'} color={'#000000'}
                                w={'100%'}
                                fontSize={'medium'}
                            >
                                map integration
                            </Text>

                        </Flex>
                    </VStack>
                    <VStack
                        w='100%'
                        justifyContent={'center'}
                        spacing={4}
                        alignItems={'flex-start'}
                        p={4}
                    >
                        <FormControl>
                            <FormLabel w={'100%'} justifyContent={'flex-start'} alignItems={'center'}
                                color={'#000000'} fontSize={'xl'}
                            >
                                Your Name
                            </FormLabel>
                            <Input type='text'
                                fontSize={'md'}
                                border={'1px solid #9F9F9F'}
                                py='8'
                                size='lg'
                                placeholder='abc' />
                        </FormControl>

                        <FormControl>
                            <FormLabel w={'100%'} justifyContent={'flex-start'} alignItems={'center'}
                                color={'#000000'} fontSize={'xl'}
                            >
                                Your Email
                            </FormLabel>
                            <Input
                                type='text'
                                py='8'
                                size='lg'
                                fontSize={'md'}
                                border={'1px solid #9F9F9F'}
                                placeholder='abc@gmail.com'
                            />
                        </FormControl>

                        <FormControl>

                            <FormLabel w={'100%'} justifyContent={'flex-start'} alignItems={'center'}
                                color={'#000000'} fontSize={'xl'}
                            >
                                Subject
                            </FormLabel>
                            <Input type='text'
                                fontSize={'md'}
                                py='8'
                                size='lg'
                                border={'1px solid #9F9F9F'} placeholder='This is an optional' />
                        </FormControl>

                        <FormControl>

                            <FormLabel w={'100%'} justifyContent={'flex-start'} alignItems={'center'}
                                color={'#000000'} fontSize={'xl'}
                            >
                                Message
                            </FormLabel>
                            <Textarea
                                type='text'
                                border='1px solid #9F9F9F'
                                h='20%'
                                placeholder='Hi! I like to ask about'
                                textAlign={'left'}
                                fontSize={'md'}
                                w={'100%'}
                            />
                        </FormControl>

                        <OutlinedButton rounded='0' type='submit' width='150px' color={'#054E47'}>SUBMIT</OutlinedButton>

                    </VStack>
                </HStack>
            </VStack>

            <PlaceSale />

        </Flex>
    );
};

export default Contact;
