import {
    Container,
    Divider,
    Heading,
    IconButton,
    Spacer,
    Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box, HStack, FormLabel, Input, Button, Flex, Image, Text, VStack
} from '@chakra-ui/react';
import { AddIcon, MinusIcon, DeleteIcon } from '@chakra-ui/icons';
import React from 'react'
import { RiDeleteBin7Fill } from 'react-icons/ri';
import { useCarts } from '../../providers/CartProvider';
import { Link } from 'react-router-dom';
import PlaceSale from '../common/PlaceSale';
import OutlinedButton from '../common/OutlinedButton';

export default function Cart() {

    var { carts, totalPrice, addCarts } = useCarts();

    const calculateDiscount = (price, sellPrice) => {
        return Math.round(((price - sellPrice) / price) * 100);
    };

    const cart_table_data = [
        {
            imgUrl: './home_row2_1.png',
            Price: 12000,
            name: 'lorum dollar',
            quantity: 2
        },
        {
            imgUrl: './home_row2_2.png',
            Price: 12000,
            name: 'lorum dollar',
            quantity: 2
        },
        {
            imgUrl: './home_row2_3.png',
            Price: 12000,
            name: 'lorum dollar',
            quantity: 2
        }
    ]

    return (
        <Flex maxW={'100%'}
            flexDirection={'column'}
            justifyContent={'space-between'} alignItems={'center'}
            bg={'#FFFFFF'}
        >
            <Image src={'./contact_banner.png'} alt='' h={'45vh'} w={'100%'} />
            <Flex
                w='80%'
                my={10}
                justifyContent={'center'} alignItems={'center'}>
                <TableContainer minH={'15vh'}
                    w={'100%'}
                >
                    <Table>
                        <Thead h={'10vh'} w={'100%'}
                        >
                            <Tr>
                                <Th className='customfont' fontSize={'xl'}
                                    color={'#000000'}
                                    w={'35%'}
                                >Product</Th>
                                <Th className='customfont'
                                    fontSize={'xl'}
                                    color={'#000000'}
                                >Price</Th>
                                <Th className='customfont' fontSize={'xl'}
                                    color={'#000000'}
                                >Quantity</Th>
                                <Th className='customfont' fontSize={'xl'}
                                    color={'#000000'}
                                >Sub total</Th>
                            </Tr>
                        </Thead>
                        <Tbody w={'100%'}
                            borderY={'1px solid #D9D9D9'}
                        >
                            {
                                cart_table_data.map((item, index) => {
                                    return <Tr key={index} h={'5vh'} w={'100%'}>
                                        <Th h={'100%'} w={'35%'}>
                                            <HStack gap={7} h={'100%'} w={'100%'}>
                                                <Image src={item.imgUrl} alt='' h={'100%'} w={'30%'} />
                                                <Text
                                                    fontSize={'medium'}
                                                    color={'#000000'}
                                                    fontWeight={500}
                                                >{item.name}</Text>
                                            </HStack>

                                        </Th>
                                        <Th fontSize={'medium'}
                                            color={'#000000'}
                                            fontWeight={500}>
                                            Rs.{item.Price}
                                        </Th>
                                        <Th>
                                            <Box
                                                display={'flex'}
                                                border={'1px solid #9F9F9F'}
                                                color={'#000000'}
                                                h={30}
                                                w={30}
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                                borderRadius={'md'}
                                            >
                                                {item.quantity}
                                            </Box>
                                        </Th>
                                        <Th
                                        >
                                            <HStack gap={5}>
                                                <Text
                                                    fontSize={'medium'}
                                                    color={'#000000'}
                                                    fontWeight={500}
                                                >
                                                    Rs.{item.Price}
                                                </Text>
                                                <RiDeleteBin7Fill size={20} />
                                            </HStack>

                                        </Th>
                                    </Tr>
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
            <HStack justifyContent={'space-between'}
                alignItems={'center'}
                my={5}
                w={'80%'}
            >
                <Flex w={'60%'} h={'100%'} justifyContent={'flex-start'} alignItems={'flex-start'}
                    gap={8}>
                    <VStack w={'60%'} h={'90%'} justifyContent={'flex-start'}
                        alignItems={'flex-start'}
                    >
                        <FormLabel
                            color={'#000000'}
                            fontSize={'xl'}
                        >Coupon Code</FormLabel>
                        <Input type='text'
                            border={'none'}
                            borderRadius={0}
                            borderBottom={'1px solid #000000'}
                            fontSize={'xl'}
                        />
                    </VStack>
                    <Button
                        bg={'none'}
                        color={'#000000'}
                        _hover={
                            { bg: 'none' }
                        }
                        w={'20%'}
                        h={'70%'}
                        fontSize={'xl'}
                    >Apply Coupon</Button>
                </Flex>
                <Flex w={'30%'}
                    h={'90%'}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    gap={'6'}
                >
                    <Button
                        bg={'none'}
                        color={'#000000'}
                        border={'none'}
                        borderBottom={'1px solid #000000'}
                        borderRadius={0}
                        _hover={
                            { bg: 'none' }
                        }
                        w={'45%'}
                        h={'70%'}
                        fontSize={'xl'}
                    >UPDATE CART</Button>
                    <Button
                        bg={'none'}
                        color={'#000000'}
                        _hover={
                            { bg: 'none' }
                        }
                        w={'45%'}
                        h={'70%'}
                        borderRadius={0}
                        border={'none'}
                        borderBottom={'1px solid #000000'}
                        fontSize={'xl'}
                    >CLEAR CART</Button>
                </Flex>
            </HStack>
            <VStack
                w={'85%'}
                justifyContent={'space-between'}
                alignItems={'center'}
                p={6}
                my={10}
                py={12}
                border={'2px solid black'}
            >
                <Flex
                    w={'90%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Text
                        fontSize={'xx-large'}
                        color={'#000000'}
                        w={'50%'}
                    >Cart totals</Text>
                    <Text
                        fontSize={'xx-large'}
                        color={'#000000'}
                        w={'25%'}
                    >SUB TOTAL</Text>
                    <Text
                        fontSize={'xx-large'}
                        color={'#000000'}
                        w={'25%'}
                        textAlign={'right'}
                    >₹1,050,000.00</Text>
                </Flex>
                <Text

                    w={'90%'}
                    textAlign={'left'}
                    fontSize={'xx-large'}
                    color={'#000000'}
                >
                    SHIPPING
                </Text>
                <VStack

                    w={'90%'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    borderY={'1px solid #000000'}
                    gap={4}
                >
                    <Text
                        w={'100%'}
                        fontSize={'lg'}
                        color={'#000000'}
                        fontWeight={700}
                    >
                        Shipping to Maharashtra
                    </Text>
                    <Text
                        w={'100%'}
                        fontSize={'lg'}
                        color={'#000000'}
                        fontWeight={600}
                    >
                        Personal Details & Address
                    </Text>
                    <Text
                        w={'45%'}
                        fontSize={'lg'}
                        color={'#8A8A8A'}
                    >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </Text>
                </VStack>


                <HStack w='100%' justifyContent='space-between'>
                    <Text
                        w={'25%'}
                        textAlign={'left'}
                        fontSize={'xx-large'}
                        color={'#000000'}
                    >
                        Total
                    </Text>
                    <Text
                        fontSize={'xx-large'}
                        color={'#000000'}
                        w={'25%'}
                        textAlign={'right'}
                    >₹1,050,000.00</Text>
                </HStack>

                <HStack w='100%' justifyContent='end'>
                    <OutlinedButton rounded='0' type='submit' height='55px' width='240px'
                        bg={'#054E47'}
                        color={'#FFFFFF'}
                        _hover={
                            { bg: '#054E47' }
                        }
                    >
                        <Link href={'/checkout'}>
                            PROCEED CHECKOUT
                        </Link>
                    </OutlinedButton>
                </HStack>

            </VStack>
            <PlaceSale />
        </Flex>
    )
}