import { Box, Stack, Checkbox, HStack, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Navigate } from 'react-router-dom';
import OutlinedButton from '../../common/OutlinedButton';
import { Link } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  const { logged, login } = useAuth();
  const [error, setError] = useState();

  const [info, setinfo] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));

      setinfo("Logging in...");
      var res = await login(values.email, values.password);
      setinfo(null);
      console.log(res);
      if (res === "err") {
        setError("Invalid Credentials !");
      } else {
        toast.success("Login Successfully !");
        navigate('/');
      }
    }

  });

  if (logged) {
    return <Navigate to='/' />
  }

  return (

    <Box bg="white" p={6} minW={'400px'} maxW={'450px'} >
      <form onSubmit={formik.handleSubmit}>
        <VStack spacing={6} align="flex-start">
          <Text fontSize='3xl' as='h2' color={'#000000'}>Log In</Text>

          {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
          {info && <Text color='blue'>{info}</Text>}
          <FormControl>
            <FormLabel htmlFor="email" color={'#000000'}>Email Address</FormLabel>
            <Input
              id="email"
              name="email"
              type="email"
              required={true}
              rounded='0'
              size="lg"
              border='1px solid #1c1c1c'
              py='6'
              placeholder='Your Email'
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password" color={'#000000'}>Password</FormLabel>
            <Input
              id="password"
              name="password"
              type="password"
              required={true}
              rounded='0'
              size="lg"
              border='1px solid #1c1c1c'
              placeholder='Your Password'
              onChange={formik.handleChange}
              value={formik.values.password}
            />
          </FormControl>
          <HStack>
            <Checkbox size='lg' /> <Text color={'#000000'}>Remember Me</Text>
          </HStack>

          <HStack w='100%' justifyContent='space-between'>
            <OutlinedButton rounded='0' type='submit' width='150px' color={'#054E47'}>Login</OutlinedButton>
            <Link to='/forgot-password'>
              <Text color={'#000000'}>Lost Your Password ?</Text>
            </Link>
          </HStack>
        </VStack>
      </form>
    </Box>
  );
};

export default Login;
