import { Container, SimpleGrid, VStack, Text, Button, Divider, HStack, Image, Flex } from '@chakra-ui/react'

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { GrUndo } from "react-icons/gr";
import { IoSearchOutline } from "react-icons/io5";
export default function MobileNav(props) {

    useEffect(() => {
        if (props.setopenMenu) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [props.setopenMenu]);

    return (
        <Container h='100%' py={5} zIndex={999} position={'absolute'} top={0} left={0}
            //  bg={'#054E47'}
            bg={'white'}
        >
            <SimpleGrid columns={[1, 1, 1, 1]} spacing='4'>
                <GrUndo size={20} onClick={() => props.setopenMenu(false)} />
                <IoSearchOutline size={20} />
                <Link to={`/`}>
                    <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                        my={2}
                        w={'100%'}
                        rounded={0}
                        justifyContent={'flex-start'}
                        borderBottom={'1px solid #E5E5E5'}
                    >
                        Home
                    </Button>
                </Link>

                <Link to={`/`}>
                    <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                        my={2}
                        w={'100%'}
                        rounded={0}
                        justifyContent={'flex-start'}
                        borderBottom={'1px solid #E5E5E5'}
                    >
                        Shop
                    </Button>
                </Link>

                <Link to={`/about`} onClick={() => props.setopenMenu(false)}>
                    <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                        my={2}
                        w={'100%'}
                        rounded={0}
                        justifyContent={'flex-start'}
                        borderBottom={'1px solid #E5E5E5'}
                    >
                        About Us
                    </Button>
                </Link>

                <Link to={`/contact`} onClick={() => props.setopenMenu(false)}>
                    <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                        my={2}
                        w={'100%'}
                        rounded={0}
                        justifyContent={'flex-start'}
                        borderBottom={'1px solid #E5E5E5'}
                        alignItems={'center'}
                    >
                        Contact Us
                    </Button>
                </Link>

                <Link to={`/cart`} onClick={() => props.setopenMenu(false)}>
                    <Button variant='ghost' borderRadius='lg' color={'black'}
                        my={2}
                        w={'100%'}
                        rounded={0}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        borderBottom={'1px solid #E5E5E5'}
                    >
                        <Text fontSize={'lg'}>Shopping Cart</Text>
                        <HStack
                            ml={5}
                            gap={1}
                            justifyContent='flex-end' alignItems={'center'}>
                            <Image src={'/cart-icon-black.png'} alt='' />
                            <Flex
                                borderRadius={'50%'}
                                bg={'black'}
                                color={'white'}
                                w={'18px'}
                                h={'21px'}
                                justifyContent={'center'}
                                alignItems='center'
                                fontSize={'sm'}
                            >
                                0
                            </Flex>
                        </HStack>
                    </Button>
                </Link>
                <Link to={`/wishlist`} onClick={() => props.setopenMenu(false)}>
                    <Button variant='ghost' borderRadius='lg' color={'black'}
                        my={2}
                        w={'100%'}
                        rounded={0}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        borderBottom={'1px solid #E5E5E5'}
                    >
                        <Text fontSize={'lg'}>Wishlist</Text>
                        <HStack
                            ml={5}
                            gap={1}
                            justifyContent='flex-end' alignItems={'center'}>
                            <Image src={'/cart-icon-black.png'} alt='' />
                            <Flex
                                borderRadius={'50%'}
                                bg={'black'}
                                color={'white'}
                                w={'18px'}
                                h={'21px'}
                                justifyContent={'center'}
                                alignItems='center'
                                fontSize={'sm'}
                            >
                                0
                            </Flex>
                        </HStack>
                    </Button>
                </Link>
                <Link to={`/login`} onClick={() => props.setopenMenu(false)}>
                    <Button variant='ghost' borderRadius='lg' color={'black'} fontSize={'lg'}
                        my={2}
                        w={'100%'}
                        rounded={0}
                        justifyContent={'flex-start'}
                        borderBottom={'1px solid #E5E5E5'}
                    >
                        Sign In
                    </Button>
                </Link>
                <HStack mt='4' pb={2}>
                    <Image src='/facebook.png' alt='' />
                    <Image src='/instagram.png' alt='' />
                </HStack>
            </SimpleGrid>
        </Container>
    )
}
