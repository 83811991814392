import {
    Menu,
    MenuButton,
    Text,
    Button,
    MenuList,
    VStack,
    Divider,
    HStack,
    MenuItem,
    useDisclosure,
    Image,
    Flex,
} from '@chakra-ui/react'
import React from 'react'
import { IoIosMenu } from "react-icons/io";
import { AiOutlineCaretDown } from 'react-icons/ai'
import { IoSearchOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

export default function NavLinks() {

    const { isOpen: isProductsOpen, onOpen: onProductsOpen, onClose: onProductsClose } = useDisclosure();

    return (
        <HStack
            h={'100%'}
            w={'98%'} justifyContent={'space-between'}>
            <HStack
                w={'5%'}
                h={'100%'}
                gap={4}
                justifyContent={'center'}
            >
                <IoIosMenu color='white' size={55} />
                <IoSearchOutline color='white' size={35} />
            </HStack>

            <HStack w={'35%'}
                h={'100%'}
                justifyContent={'space-evenly'}

            >
                <Link to={`/`}>
                    <Button
                        fontSize={['sm', 'md', 'md', 'xl']}
                        _hover={
                            { bg: 'none' }
                        }
                        h={'100%'}
                        fontWeight={400}
                        variant='ghost' borderRadius='lg' color='#FFFFFF'>
                        Home
                    </Button>
                </Link>

                <Link to={`/about`}>
                    <Button
                        fontSize={['sm', 'md', 'md', 'xl']}
                        color='#FFFFFF'
                        _hover={
                            { bg: 'none' }
                        }
                        h={'100%'}
                        fontWeight={400}
                        variant='ghost' borderRadius='lg'>
                        About Us
                    </Button>
                </Link>
            </HStack>

            <Menu isOpen={isProductsOpen} matchWidth={true}>
                {({ isOpen }) => (
                    <>
                        <MenuButton
                            isActive={isOpen}
                            as={Button}
                            colorScheme='primary'
                            variant='ghost'
                            borderRadius='lg'
                            onMouseEnter={onProductsOpen}
                            onMouseLeave={onProductsClose}
                            rightIcon={null}
                            fontSize={['sm', 'md', 'md', 'xl']}
                            color='#FFFFFF'
                            _hover={
                                { bg: 'none' }
                            }
                            h={'100%'}
                            fontWeight={400}
                        >
                            Shop
                        </MenuButton>

                        <MenuList w='75vw' mt='-2'
                            // mx='-30vw'
                            mx={'-30vw'}
                            onMouseEnter={onProductsOpen} onMouseLeave={onProductsClose} p='0'
                            //  bg='white'
                            bg='#054E47'
                            border={'none'}
                        >
                            <HStack p='10' alignItems='start' w={'100%'} justifyContent={'center'} h='100%'>

                                <VStack alignItems='start' justifyContent='space-between' h='100%' mx={10} gap={6} my={2}>
                                    <Text fontSize='lg' className='custom' color={'white'}>COLLECTIONS</Text>
                                    <Text fontSize='md' className='custom' color={'white'}>Celebrities Closet</Text>
                                    <Text fontSize='md' className='custom' color={'white'}>Categories</Text>
                                    <Text fontSize='md' className='custom' color={'white'}>Accesories</Text>
                                </VStack>

                                <VStack alignItems='start' justifyContent='space-between' h='100%' gap={6} mx={10} my={2}>
                                    <Text fontSize='lg' className='custom' color={'white'}>Collections</Text>
                                    <Text fontSize='md' className='custom' color={'white'}>Festival 2023</Text>
                                    <Text fontSize='md' className='custom' color={'white'}>Spring 2024</Text>
                                    <Text fontSize='md' className='custom' color={'white'}>Festival 2024</Text>
                                    <Text fontSize='md' className='custom' color={'white'}>Festival 2024</Text>
                                </VStack>

                                <VStack alignItems='start' justifyContent='space-between' h='100%' gap={6} mx={12} my={2}>
                                    <Text fontSize='lg' className='custom' color={'white'}>CATEGORY</Text>
                                    <HStack gap={3}>
                                        <Text fontSize='lg' className='custom' color={'white'}>GIRLS</Text>
                                        <Text fontSize='md' fontWeight={300} className='custom' color={'white'}>Party Wear</Text>
                                    </HStack>
                                    <Text fontSize='md' fontWeight={300} className='custom' w={'100%'} pl={55} color={'white'}>Co-erd Sets</Text>
                                    <Text fontSize='md' fontWeight={300} className='custom' w={'100%'} pl={55} color={'white'}>Lehenga Sets</Text>
                                    <HStack gap={3}>
                                        <Text fontSize='lg' className='custom' color={'white'}>BOYS</Text>
                                        <Text fontSize='md' fontWeight={300} className='custom' color={'white'}>Lorem Ipusum</Text>
                                    </HStack>
                                </VStack>


                                <VStack alignItems='start' justifyContent='space-between' h='100%' gap={6} my={2}>
                                    <Text fontSize='lg' className='custom' color={'white'}>ACCESORIES</Text>
                                    <Text fontSize='md' fontWeight={300} className='custom' color={'white'}>Hair Accesories</Text>
                                    <Text fontSize='md' fontWeight={300} className='custom' color={'white'}>Shoes</Text>
                                </VStack>
                            </HStack>

                        </MenuList>
                    </>
                )}
            </Menu>

            <Flex
                justifyContent={'center'}
                alignItems={'center'}
                w={'12%'}
                p={4}>
                <Image src={'/logo.png'} alt='' h={'90%'}
                    w={'90%'}
                />
            </Flex>

            <HStack w={'35%'}
                justifyContent={'space-evenly'}
                h={'100%'}
            >

                <Link to={`/contact`}>
                    <Button
                        fontSize={['sm', 'md', 'md', 'xl']}
                        color='#FFFFFF'
                        _hover={
                            { bg: 'none' }
                        }
                        h={'100%'}
                        fontWeight={400}
                        variant='ghost' borderRadius='lg'>
                        Contact Us
                    </Button>
                </Link>

                <Link to={`/wishlist`}>
                    <Button
                        fontSize={['sm', 'md', 'md', 'xl']}
                        color='#FFFFFF'
                        _hover={
                            { bg: 'none' }
                        }
                        h={'100%'}
                        fontWeight={400}
                        variant='ghost' borderRadius='lg'>
                        Wishlist
                    </Button>
                </Link>

                <Link to={`/login`}>
                    <Button
                        fontSize={['sm', 'md', 'md', 'xl']}
                        color='#FFFFFF'
                        h={'100%'}
                        fontWeight={400}
                        variant='ghost' borderRadius='lg'
                        _hover={
                            { bg: 'none' }
                        }
                    >
                        Signin
                    </Button>
                </Link>
            </HStack>

            <HStack as={Link} to={'/cart'} spacing='1' w={'5%'}
            >
                <Image src={'/Cart_icon.png'} alt='' h={'90%'} w={'50%'} />
                <Flex
                    borderRadius={'50%'}
                    bg={'#ECE7E3'}
                    color={'#966754'}
                    w={'20px'}
                    h={'20px'}
                    justifyContent={'center'}
                    alignItems='center'
                >
                    1
                </Flex>

            </HStack>
        </HStack>
    )
}


// function MegaMenuItem({ title, description }) {
//     return (
//         <VStack cursor='pointer' w='100%' _hover={{ bg: 'whatsapp.100' }} rounded='lg' bg='gray.100' p='3' alignItems='start' spacing='2'>
//             <Text className='custom' fontSize='lg' fontWeight='800'>{title}</Text>
//             <Text fontWeight='600'>{description}</Text>
//         </VStack>
//     )
// }
