import React from 'react';
import { Container, Card, Text, VStack } from "@chakra-ui/react"

export default function Disclaimer() {
  return (
    <Container maxW='8xl' my='6'>
      <VStack w='100%' spacing='4'>
        <Card p='5' w='100%'>
          <Text fontSize='xl' className='customfont'>Disclaimer</Text>
        </Card>
        <Card p='8' w='100%'>
          <Text>Disclaimer</Text>
        </Card>
      </VStack>
    </Container>
  )
}
