import { Flex, Text, VStack, Button, Box, SimpleGrid, Container } from '@chakra-ui/react'
import React from 'react'
import HomeRowCard from '../../common/HomeRowCard'
import ReviewCard from '../../common/ReviewCard'
import HomeRowLargeCard from '../../common/HomeRowLargeCard'
import PlaceSale from '../../common/PlaceSale'


export default function Home() {

    const home_row2_data = [
        {
            imgUrl: '/home_row2_1.png',
            Price: 2000,
            name: 'lorum dollar tshirt'
        }, 
        {
            imgUrl: '/home_row2_2.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row2_3.png',
            Price: 54000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row2_1.png',
            Price: 34000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row_2_4.png',
            Price: 23999,
            name: 'lorum dollar tshirt'
        }
    ]

    const home_row4_data = [
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_2.png',
            Price: 9000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_3.png',
            Price: 12000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_4.png',
            Price: 1200,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_5.png',
            Price: 23000,
            name: 'lorum dollar tshirt'
        }
    ]

    const home_row3_data = [
        {
            imgUrl: '/home_row3_1.png',
            name: 'FESTIVAL'
        },
        {
            imgUrl: '/home_row3_2.png',
            name: 'FESTIVAL'
        },
        {
            imgUrl: '/home_row3_3.png',
            name: 'FESTIVAL'
        },
        {
            imgUrl: '/home_row3_2.png',
            name: 'FESTIVAL'
        }
    ]

    return (
        <Flex
            flexDirection={'column'}
            justifyContent={'space-between'} alignItems={'center'}
            bg={'#FFFFFF'}
        >
            <Box
                backgroundImage='/home_banner.png'
                maxH='75vh'
                height='70vh'
                width='100%'
                backgroundSize='cover'
                backgroundPosition='center'
                backgroundRepeat='no-repeat'
            />

            <Container maxW='9xl' py='12'>

                <VStack w='100%' spacing='6'>

                    <SimpleGrid columns={[2, 2, 3, 5]} w='100%' spacing={4}>
                        {
                            home_row2_data.map((item, index) => {
                                return <HomeRowCard item={item} key={index} />
                            })
                        }
                    </SimpleGrid>

                    <SimpleGrid columns={[1, 1, 2, 2]} w='100%' spacing={4} px={['0', '0', '0', '12']}>
                        {
                            home_row3_data.map((item, index) => {
                                return <HomeRowLargeCard item={item} key={index} />
                            })
                        }
                    </SimpleGrid>


                    <Text textAlign={'center'} fontSize={'xx-large'}
                        color={'#966754'}
                    >
                        CELEBRITY CLOSET
                    </Text>

                    <SimpleGrid columns={[2, 2, 3, 5]} w='100%' spacing={4}>
                        {home_row4_data.map((item, index) => {
                            return <HomeRowCard item={item} key={index} />
                        })}
                    </SimpleGrid>

                    <PlaceSale />

                </VStack>

            </Container>

            <Flex
                display={['none', 'none', 'flex', 'flex']}
                bg={'#F8F3EF'}
                h={'70vh'}
                w={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <VStack h={'90%'} w={'40%'}
                    justifyContent={'center'}
                    gap={8}
                    alignItems={'flex-start'}
                    pl={'20'}
                >
                    <Text
                        fontSize={'xx-large'}
                        fontWeight={700}
                        color={'#000000'}
                    >MORE THAN 1000+ BRANDS TRUSTS US</Text>
                    <Text
                        fontSize={'xl'}
                        color={'#000000'}
                    >Im a paragraph. Click here to add your own text and edit me.
                        Its easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.</Text>
                    <Button
                        bg={'#F8F3EF'}
                        color={'#000000'}
                        fontSize={'xl'}
                        _hover={
                            {
                                bg: '#F8F3EF'
                            }
                        }
                        border={'1px solid #4E6F524D'}
                        borderRadius={'30px'}
                        w={'50%'}
                    >Explore all reviews</Button>
                </VStack>
                <Flex h={'90%'} w={'45%'}
                    justifyContent={'space-evenly'}
                    alignItems={'center'}
                    overflow='hidden'
                >
                    <VStack
                        w={'50%'}
                        mt={30}
                        gap={3}
                        // position='relative'
                        maxHeight='100%'
                    >
                        <ReviewCard />
                        <ReviewCard />
                        <ReviewCard />
                    </VStack>
                    <VStack
                        w={'50%'}
                        gap={3}
                        alignItems={'center'}
                        justifyContent={'start'}
                    // position='relative'
                    // maxHeight='100%'
                    >
                        <ReviewCard />
                        <ReviewCard />
                        <ReviewCard />
                    </VStack>
                </Flex>
            </Flex>

            {/* Mobile Screen  */}
            <Flex
                display={['flex', 'flex','none', 'none']}
                bg={'#F8F3EF'}
                h={'70vh'}
                w={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
                flexDirection={'column'}
            >
                <VStack
                    justifyContent={'center'}
                    spacing={4}
                    alignItems={'center'}
                >
                    <Text
                        fontSize={'xx-large'}
                        fontWeight={700}
                        color={'#054E47'}
                        textAlign={'center'}
                        w={'100%'}
                        my={5}
                    >MORE THAN 1000+<br/> BRANDS TRUSTS US</Text>
                    <Text
                        fontSize={'lg'}
                        color={'#000000'}
                        w={'100%'}
                        mb={3}
                        textAlign={'center'}
                    >Click here to add your own text and edit me.</Text>
                </VStack>
                <Flex
                my={7}
                    justifyContent={'space-evenly'}
                    alignItems={'center'}
                    overflow='hidden'
                    px={7}
                >
                    <VStack
                        w={'50%'}
                        mt={30}
                        gap={3}
                        // position='relative'
                        maxHeight='100%'
                    >
                        <ReviewCard />
                        <ReviewCard /> 
                        <ReviewCard />
                    </VStack>
                    <VStack
                        w={'50%'}
                        gap={3}
                        alignItems={'center'}
                        justifyContent={'start'}
                    // position='relative'
                    // maxHeight='100%'
                    >
                        <ReviewCard />
                        <ReviewCard />
                        <ReviewCard /> 
                    </VStack>
                </Flex>
            </Flex>
        </Flex>
    )
}
