import { Button, Flex, Box, VStack, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { toast } from "react-toastify";
import OutlinedButton from '../../common/OutlinedButton';

const ForgotPassword = () => {
  const { forgotPassword } = useAuth();

  const navigate = useNavigate();

  const [error] = useState();

  const [info] = useState(null);

  const formik = useFormik({

    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      const res = await forgotPassword(values.email);
      if (res === "success") {
        toast.success("Otp Sent Successfully !");
        navigate("/otp-page", { state: { email: values.email } });
      }
    }

  });

  return (
    <Flex minH='60vh' bg='white' align="center" justify="center">
      <Box bg="white" p={6} maxW={'500px'} w='90%' my={6}>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={6} align="flex-start">
            <Text fontSize='3xl' as='h3'>Forgot Password</Text>

            {error ? <Box bg='red' w='100%' py='1' textAlign='center' color='white' > {error} </Box> : <></>}
            {info && <Text color='blue'>{info}</Text>}
            <FormControl>
              <FormLabel htmlFor="email">Your Email Adddress</FormLabel>
              <Input
                id="email"
                name="email"
                type="email"
                required={true}
                rounded='0'
                size="lg"
                border='1px solid #1c1c1c'
                placeholder='Your Email'
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </FormControl>
            <Text>
              We will send you an OTP to verify your email address and then you can reset your password.
            </Text>
            <OutlinedButton type="submit" rounded='0' width='150px'>
              Next
            </OutlinedButton>
          </VStack>
        </form>
      </Box>
    </Flex>
  );
};

export default ForgotPassword;

