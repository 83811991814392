import {
    Box,
    HStack,
    useDisclosure,
    Flex,
    Input,
    Button,
    IconButton,
    Image,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import NavLinks from './NavLinks';
// import { BiLaptop } from "react-icons/bi";
import { IoIosMenu } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";

// import { IoClose } from "react-icons/io5";
import 'react-toastify/dist/ReactToastify.css';
import { useCarts } from '../../providers/CartProvider';
import MobileNav from './MobileNav';
import { ToastContainer } from 'react-toastify';

export default function NavBar() {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchEnabled, setsearchEnabled] = useState(false);
    const { carts } = useCarts();

    const [searchText, setSearchText] = useState("");
    const [openMenu, setopenMenu] = useState("")

    return (
        <>
            <Box bg='#054E47' position='sticky' top='0' px={4} zIndex='2'>
                <Flex h={20} maxW='100vw' display={['none', 'none', 'flex', 'flex']} alignItems={'center'} justifyContent={'space-between'}>
                    {/* <IconButton
                        size={'md'} 
                        icon={isOpen ? <IoClose/> : <FiMenu/>}
                        // icon={isOpen ? <BiLaptop /> : <BiLaptop />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    /> */}
                    <HStack w='100%' spacing={5} alignItems={'center'} >

                        <HStack as={'nav'} w='100%' alignItems={'center'} justifyContent={'center'} spacing={2} display={{ base: 'none', md: 'flex' }}>
                            <NavLinks />
                        </HStack>

                    </HStack>
                    {/* <Flex alignItems={'center'}>
                        <Button

                            variant={'solid'}
                            colorScheme={'primary'}
                            size={'md'}
                            mr={4}
                            borderRadius='md'>
                            Signin
                        </Button>
                    </Flex> */}
                </Flex>
                <Flex h={20} maxW='100vw' display={['flex', 'flex', 'none', 'none']} alignItems={'center'} justifyContent={'space-between'}>
                    <IoIosMenu color='white' size={30} onClick={() => setopenMenu(true)} />
                    <Flex
                        justifyContent={'center'}
                        alignItems={'center'}
                        p={2}
                        pl={20}
                        // mx={5}
                        mr='2'
                    >
                        <Image src={'/logo.png'} alt='' h={'90%'}
                            w={'90%'}
                        /></Flex>
                    <HStack
                        minW='80px'
                        ml={5}
                        gap={1}
                        justifyContent='flex-end' alignItems={'center'}>
                        <IoSearchOutline color='white' size={18} />
                        <img
                            src={'/Cart_icon.png'} alt='Cart Icon'
                            h={''}
                            w={'28%'}
                        />
                        <Flex
                            borderRadius={'50%'}
                            bg={'#ECE7E3'}
                            color={'#966754'}
                            w={'18px'}
                            h={'18px'}
                            justifyContent={'center'}
                            alignItems='center'
                        >
                            1

                        </Flex>
                    </HStack>

                </Flex>
                {/* {isOpen ? (
                    <MobileNav />
                ) : null} */}
            </Box >

            {
                openMenu ? <MobileNav setopenMenu={setopenMenu} /> : null
            }
            <ToastContainer />
        </>
    )
}
