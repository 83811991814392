import { Flex, Text, Button, VStack, HStack } from '@chakra-ui/react'
import React from 'react'
import OutlinedButton from './OutlinedButton'
import { NavLink } from 'react-router-dom'

export default function HomeRowLargeCard(props) {
    return (
                   <NavLink to={'/festival'}> <Flex
            bgImage={props.item.imgUrl}
            backgroundSize={'cover'}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            h={'45%'}
            minH={['250px', '250px', '300px', '400px']}
            w={['100%', '100%', '100%', '100%']}
            justifyContent={'flex-start'}
            alignItems={'flex-end'}
        >



            <VStack
                h={'50%'}
                w={'30%'}
                ml={10}
                mb={6}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'start'} alignItems={'center'} >
                <Text
                    // h={'10%'}
                    w={'100%'}
                    color={'#FFFFFF'}
                >2024</Text>
                <Text
                    // h={'35%'} 
                    w={'100%'}
                    fontSize={['2xl', '2xl', '2xl', '4xl']}
                    color={'#FFFFFF'}
                >{props.item.name}</Text>
                <HStack w='100%'>
                    <OutlinedButton rounded='0' type='submit' width='150px' color={'#FFFFFF'}
                        border={'2px solid #FFFFFF'}
                        _hover={
                            {
                                bg: 'none'
                            }
                        }
                    >
                        Shop Now</OutlinedButton>
                </HStack>
            </VStack>
           
        </Flex> 
        </NavLink>
    )
}
