import { HStack, VStack, Flex, Box, Text, Button } from '@chakra-ui/react'
import React from 'react'
import { FaStar } from "react-icons/fa";

export default function ReviewCard() {
    return (
        <VStack h={'250px'} w={'300px'}
            justifyContent={'space-between'}
            alignItems={'center'}
            borderRadius={'xl'}
            bg='#FFFFFF'
            p={3}
        >
            <HStack h={'10%'} w={'90%'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                gap={2}
            >
                <Flex w={'50%'}>
                    <FaStar color='#FFDA5B' />
                    <FaStar color='#FFDA5B' />
                    <FaStar color='#FFDA5B' />
                    <FaStar color='#FFDA5B' />
                    <FaStar border='#FFDA5B' />
                </Flex>
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bg={'#DFE9FF'}
                    borderRadius={'md'}
                    w={'20%'}
                    color='#000000'
                >
                    4/5
                </Box>
            </HStack>
            <Flex h={'50%'}
                w={'90%'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                gap={5}
            >
                <Box
                    h={10}
                    w={14}
                    bg={'#F8F3EF'}
                    borderRadius='50%'
                >
                </Box>
                <VStack
                    h={'100%'}
                    w={'100%'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                    gap={1}
                >
                    <Text color={'#000000'}
                        fontSize={'small'}
                    >
                        Emily
                    </Text>
                    <Flex>
                        <FaStar color='#FFDA5B' />
                        <FaStar color='#FFDA5B' />
                        <FaStar color='#FFDA5B' />
                        <FaStar color='#FFDA5B' />
                        <FaStar border='#FFDA5B' />
                    </Flex>
                    <Text fontSize={'x-small'}
                        color={'#000000'}
                    >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                    </Text>
                </VStack>
            </Flex>
            <Button h={'20%'}
                w={'90%'}
                bg={'#054E47'}
                borderRadius={'xl'}
                color={'#FFFFFF'}
                fontSize={'md'}
            >
                Read More
            </Button>
        </VStack>
    )
}
