import { Box, Flex, HStack, Image, SimpleGrid, Text, VStack, Button, Container } from '@chakra-ui/react'
import React from 'react'
import PlaceSale from '../common/PlaceSale'
import OutlinedButton from '../common/OutlinedButton'
import HomeRowCard from '../common/HomeRowCard'

const Festival = () => {
    const festival_data = [
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        }
    ]
    return (
        <Flex
            flexDirection={'column'}
            justifyContent={'space-between'} alignItems={'center'}
            bg={'#FFFFFF'}
        >
            <Box
                backgroundImage='/spring_banner.png'
                maxH='75vh'
                height='70vh'
                width='100%'
                backgroundSize='cover'
                backgroundPosition='center'
                backgroundRepeat='no-repeat'
            />
            <Container maxW='9xl' py='12'
            >
                <VStack spacing={4} w={'100%'}
                >
                    <Text fontSize={'x-large'} fontWeight={800}
                        w={'100%'}
                        textAlign={'start'}
                        pl={10}
                    >2024 | SPRING</Text>
                    <HStack w={'100%'}
                        py={4}
                        px={6}
                        justifyContent={'space-between'}
                    >
                        <HStack spacing={5}>
                            <Image src='./filter_icon.png' alt='' />
                            <Text fontSize={'lg'}>Filter</Text>
                            <Text fontSize={'lg'} borderLeft={'2px solid #9F9F9F'} pl={4} textAlign={'start'}>Showing 1–16 of 32 results</Text>
                        </HStack>
                        <HStack spacing={5}>
                            <Text fontSize={'lg'}>Show</Text>
                            <Box
                                p={4}
                                justifyContent={'center'}
                                border={'1px solid black'}>12</Box>
                            <Text fontSize={'lg'}>Sort by</Text>
                            <OutlinedButton rounded='0' type='submit' width='150px' color={'#000000'}
                                border={'1px solid #000000'}
                                _hover={
                                    {
                                        bg: 'none'
                                    }
                                }
                            >
                                Default</OutlinedButton>
                        </HStack>
                    </HStack>
                    <SimpleGrid columns={[2, 2, 3, 4]} w='100%' spacing={4}>
                        {
                            festival_data.map((item, index) => {
                                return <HomeRowCard item={item} key={index} />
                            })
                        }
                    </SimpleGrid>
                    <HStack spacing={6}
                        justifyContent={'center'}
                        alignItems={'center'}
                        my={5}
                    >
                        <Button
                            fontSize={'lg'}
                            bg='#F4F1EF'
                            _hover={
                                { bg: '#F4F1EF' }
                            }
                            rounded={3}
                        >
                            1
                        </Button>
                        <Button
                            fontSize={'lg'}
                            bg='#F4F1EF'
                            _hover={
                                { bg: '#F4F1EF' }
                            }
                            rounded={3}
                        >
                            2
                        </Button>
                        <Button
                            fontSize={'lg'}
                            bg='#F4F1EF'

                            _hover={
                                { bg: '#F4F1EF' }
                            }
                            rounded={3}
                        >
                            3
                        </Button>
                        <Button
                            fontSize={'lg'}
                            bg='#F4F1EF'

                            _hover={
                                { bg: '#F4F1EF' }
                            }
                            rounded={3}
                        >
                            Next
                        </Button>
                    </HStack>
                </VStack>
            </Container>
            <PlaceSale />
        </Flex>
    )
}

export default Festival