import React, { useEffect, useState } from 'react'
import { Container, Box, HStack, Image, SimpleGrid, Divider, VStack, Icon, Text } from '@chakra-ui/react';
import { FaEnvelope, FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { useCategories } from '../../providers/CategoriesProvider';

export default function Footer() {
    const { categories } = useCategories();

    const [filteredCategories, setfilteredCategories] = useState([]);

    useEffect(() => {
        // get only first 6 categories
        if (categories.length > 6) {
            setfilteredCategories(categories.slice(0, 6));
        } else {
            setfilteredCategories(categories);
        }

    }, [categories])


    return (<>

        <Box w='100%' bg='#054E47' style={{ width: '100%' }} display={['flex', 'flex', 'none', 'none']} alignItems='center' justifyContent={'center'} py='12'>
            <VStack w={'85%'} justifyContent={'center'} alignItems={'flex-start'}>

                <HStack w={'100%'}
                    justifyContent={'space-between'}
                    alignItems={'start'}
                    display={'flex'}
                    spacing='5'
                    flexDirection={['column', 'column', 'column', 'row']}
                >
                    <HStack 
                    w={'100%'}
                        justifyContent={'space-between'}
                        alignItems={'start'}
                        display={'flex'}
                        spacing='5'
                        flexDirection='row'
                    >
                        <VStack
                            w={['100%', '100%', '100%', '23%']}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            spacing={4} 
                        >
                            <Text
                                color='white'
                                fontSize={'20px'}
                                textAlign={'left'}
                                w={'100%'}
                            >USEFUL LINKS</Text>
                            <Link href={'/about-us'}>
                                <Text
                                    color='white'
                                    fontSize={'lg'}
                                    textAlign={'left'}
                                    w={'100%'}
                                    fontWeight={400}
                                >About Us</Text>
                            </Link>
                            <Link href={'/contact-us'}>
                                <Text
                                    color='white'
                                    fontSize={'lg'}
                                    textAlign={'left'}
                                    w={'100%'}
                                    fontWeight={400}
                                >Contact Us</Text>
                            </Link>
                        </VStack>
                        <VStack
                            w={['100%', '100%', '100%', '23%']}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            spacing={4}
                        >
                            <Text
                                color='white'
                                fontSize={'20px'}
                                textAlign={'left'}
                                w={'100%'}
                            >MY ACCOUNT</Text>
                            <Link href={'/track-myorder'}>
                                <Text
                                    color='white'
                                    fontSize={'lg'}
                                    textAlign={'left'}
                                    w={'100%'}
                                    fontWeight={400}
                                >Track My Order</Text>
                            </Link>
                            <Link href={'/privacy-policy'}>
                                <Text
                                    color='white'
                                    fontSize={'lg'}
                                    textAlign={'left'}
                                    w={'100%'}
                                    fontWeight={400}
                                >Privacy Policy</Text>
                            </Link>
                        </VStack>
                    </HStack>
                    <VStack
                        w={['100%', '100%', '100%', '25%']}
                        justifyContent={'flex-start'}
                        alignItems={'flex-start'}
                        spacing={4}
                    >
                        <Text
                            color='white'
                            fontSize={'20px'}
                            textAlign={'left'}
                            w={'100%'}
                        >CUSTOMER SERVICE</Text>
                        <Link href={'/returns-refunds'}>
                            <Text
                                color='white'
                                fontSize={'lg'}
                                textAlign={'left'}
                                w={'100%'}
                            >Returns & Refunds</Text>
                        </Link>
                        <Link href={'/terms-conditions'}>
                            <Text
                                color='white'
                                fontSize={'lg'}
                                textAlign={'left'}
                                w={'100%'}
                            >Terms & Conditions</Text>
                        </Link>
                    </VStack>
                    <VStack
                        w={['100%', '100%', '100%', '35%']}
                        spacing={4}
                        justifyContent={'flex-start'}
                        alignItems={'flex-start'}
                    >
                        <Text
                            color='white'
                            fontSize={'20px'}
                            textAlign={'left'}
                            w={'100%'}
                        >We're available by phone +123-456-789</Text>
                        <Text
                            color='white'
                            fontSize={'lg'}
                            textAlign={'left'}
                            w={'100%'}
                            fontWeight={400}
                        >info@example.com</Text>
                        <Text
                            color='white'
                            fontSize={'lg'}
                            textAlign={'left'}
                            w={'100%'}
                            fontWeight={400}
                        >Monday till Friday 10 AM to 7 PM</Text>
                    </VStack>
                </HStack>
                <HStack my='8'
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    spacing={10}
                >
                    <Text
                        color='white'
                        fontSize={'lg'}
                        fontWeight={400}
                    >Designed & Powered by Rooster-Tale</Text>
                    <Text
                        color='white'
                        fontSize={'lg'}
                        fontWeight={400}
                    >©2024 COCO. All Rights Reserved</Text>
                </HStack>
            </VStack>
        </Box>
                <Box w='100%' bg='#054E47' style={{ width: '100%' }} display={['none', 'none','flex', 'flex']} alignItems='center' justifyContent={'center'} py='12'>
                <VStack w='100%' px={'12'} justifyContent={'center'} alignItems={'flex-start'}>
    
                    <HStack w={'100%'}
                        justifyContent={'space-between'}
                        alignItems={'start'}
                        display={'flex'}
                        spacing='5'
                    >
                            <VStack
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}
                                spacing={5} 
                            >
                                <Text
                                    color='white'
                                    fontSize={'x-large'}
                                    textAlign={'left'}
                                    w={'100%'}
                                >USEFUL LINKS</Text>
                                <Link href={'/about-us'}>
                                    <Text
                                        color='white'
                                        fontSize={'large'}
                                        textAlign={'left'}
                                        w={'100%'}
                                        fontWeight={400}
                                    >About Us</Text>
                                </Link>
                                <Link href={'/contact-us'}>
                                    <Text
                                        color='white'
                                        fontSize={'large'}
                                        textAlign={'left'}
                                        w={'100%'}
                                        fontWeight={400}
                                    >Contact Us</Text>
                                </Link>
                            </VStack>
                            <VStack
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}
                                spacing={5}
                            >
                                <Text
                                    color='white'
                                    fontSize={'x-large'}
                                    textAlign={'left'}
                                    w={'100%'}
                                >MY ACCOUNT</Text>
                                <Link href={'/track-myorder'}>
                                    <Text
                                        color='white'
                                        fontSize={'large'}
                                        textAlign={'left'}
                                        w={'100%'}
                                        fontWeight={400}
                                    >Track My Order</Text>
                                </Link>
                                <Link href={'/privacy-policy'}>
                                    <Text
                                        color='white'
                                        fontSize={'large'}
                                        textAlign={'left'}
                                        w={'100%'}
                                        fontWeight={400}
                                    >Privacy Policy</Text>
                                </Link>
                            </VStack>
                        <VStack
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            spacing={5}
                        >
                            <Text
                                color='white'
                                fontSize={'x-large'}
                                textAlign={'left'}
                                w={'100%'}
                            >CUSTOMER SERVICE</Text>
                            <Link href={'/returns-refunds'}>
                                <Text
                                    color='white'
                                    fontSize={'large'}
                                    textAlign={'left'}
                                    w={'100%'}
                                >Returns & Refunds</Text>
                            </Link>
                            <Link href={'/terms-conditions'}>
                                <Text
                                    color='white'
                                    fontSize={'large'}
                                    textAlign={'left'}
                                    w={'100%'}
                                >Terms & Conditions</Text>
                            </Link>
                        </VStack>
                        <VStack
                            spacing={5}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                        >
                            <Text
                                color='white'
                                fontSize={'x-large'}
                                textAlign={'left'}
                                w={'100%'}
                            >We're available by phone +123-456-789</Text>
                            <Text
                                color='white'
                                fontSize={'large'}
                                textAlign={'left'}
                                w={'100%'}
                                fontWeight={400}
                            >info@example.com</Text>
                            <Text
                                color='white'
                                fontSize={'large'}
                                textAlign={'left'}
                                w={'100%'}
                                fontWeight={400}
                            >Monday till Friday 10 AM to 7 PM</Text>
                        </VStack>
                    </HStack>
                    <HStack my='8'
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        spacing={10}
                    >
                        <Text
                            color='white'
                            fontSize={'lg'}
                            fontWeight={400}
                        >Designed & Powered by Rooster-Tale</Text>
                        <Text
                            color='white'
                            fontSize={'lg'}
                            fontWeight={400}
                        >©2024 COCO. All Rights Reserved</Text>
                    </HStack>
                </VStack>
            </Box>    </>
    );
}
