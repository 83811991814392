"use client"

import {
    Button,
} from '@chakra-ui/react';
import React from 'react'

export default function OutlinedButton({ children, ...props }) {
    return (
        <Button
            variant='outlined'
            border='1px solid #1c1c1c'
            _hover={{ bg: '#1c1c1c', color: '#fefefe' }}
            color="#1c1c1c"
            py={['1', '1', '1', '6']}
            fontSize={['sm', 'sm', 'sm', 'lg']}
            {...props}
        >
            {children}
        </Button>
    )
}
