import React from 'react';
import { Container, Card, Text, VStack } from "@chakra-ui/react"

export default function ShippingAndDelivery() {
    return (
        <>
            <Container maxW='8xl' my='6'>
                <VStack w='100%' spacing='4'>
                    <Card p='5' w='100%'>
                        <Text fontSize='2xl' fontWeight='800' className='customfont'>Shipping and Delivery</Text>
                    </Card>
                    <Card p='5' w='100%'>
                        <VStack w='100%' spacing='4' alignItems='start'>

                            <Text>Last updated on August 1 2024</Text>

                            <Text>For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only.</Text>

                            
                        </VStack>
                    </Card>
                </VStack>
            </Container>
        </>
    )
}
