import {
    Container,
    VStack,
    Divider,
    HStack,
    Button,
    Flex,
    Image,
    Text,
    Card,
    Heading,
    Box,
    Stack,
    FormLabel,
    Input,
    RadioGroup,
    Radio
} from '@chakra-ui/react';
import React from 'react'
import { useCarts } from '../../providers/CartProvider';
// import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useUser } from '../../providers/UserProvider';
import { Link } from 'react-router-dom';
import OutlinedButton from '../common/OutlinedButton';
import PlaceSale from '../common/PlaceSale';

export default function CheckOut() {

    const { address } = useUser();
    const [selectedAddress, setSelectedAddress] = React.useState(0)
    var { carts, totalPrice } = useCarts();
    const [value, setValue] = React.useState('COD')

    const calculateDiscount = (price, sellPrice) => {
        return Math.round(((price - sellPrice) / price) * 100);
    };

    const [paymentMethod, setPaymentMethod] = React.useState('cod');

    // const formik = useFormik({
    //     initialValues: {
    //         address: "",
    //         phone: "",
    //         locality: "",
    //         pincode: "",
    //         city: "",
    //         state: "",
    //     },
    //     onSubmit: async (values) => {
    //         // var res = await addAddress(values.address, values.phone, values.locality, values.city, values.state, values.pincode);

    //         // if (res === "err") {
    //         //     // setError("Error Adding Contact !");
    //         // } else {
    //         //     values.address = "";
    //         //     values.phone = "";
    //         //     values.locality = "";
    //         //     values.city = "";
    //         //     values.state = "";
    //         //     values.pincode = "";
    //         // }
    //     }

    // });


    function orderproduct() {
        toast.error(`Unexpected Server Error. try gaain`);
    }


    return (
        <>
            <VStack minH={'100vh'} maxW={'100vw'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Image src={'./contact_banner.png'} alt='' h={'45vh'} w={'100%'} />
                <HStack justifyContent={'space-between'}
                    alignItems={'center'}
                    h={'150vh'}
                    w={'80%'}
                    mt={'50'}
                    mb={'30'}
                >
                    <Flex h={'100%'}
                        w={'47%'}
                        justifyContent={'space-between'}
                        alignItems={'flex-start'}
                        flexDirection={'column'}
                    >
                        <Text
                            fontSize={'x-large'}
                            color={'#000000'}
                            h={'6%'}
                            w={'100%'}
                            textAlign={'left'}
                            fontWeight={700}
                        >Billing details</Text>
                        <HStack h={'9%'}
                            w={'100%'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <VStack h={'100%'}
                                w={'45%'}
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}>
                                <FormLabel
                                    fontSize={'lg'}
                                    color={'#000000'}
                                    h={'20%'}
                                >
                                    First Name
                                </FormLabel>
                                <Input type='text'
                                    borderRadius={0}
                                    border={'1px solid #000000'}
                                    rounded='0'
                                    size="lg"
                                    _focusVisible={
                                        {
                                            border: '1px solid #000000'
                                        }
                                    }
                                />
                            </VStack>
                            <VStack h={'100%'}
                                w={'45%'}
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}>
                                <FormLabel
                                    fontSize={'lg'}
                                    color={'#000000'}
                                    h={'20%'}
                                >
                                    Last Name
                                </FormLabel>
                                <Input type='text'
                                    borderRadius={0}
                                    rounded='0'
                                    size="lg"
                                    border={'1px solid #000000'}
                                />
                            </VStack>
                        </HStack>
                        <VStack h={'9%'} w={'100%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <FormLabel
                                fontSize={'lg'}
                                color={'#000000'}
                                h={'20%'}
                            >
                                Company Name (optional)
                            </FormLabel>
                            <Input type='text'
                                borderRadius={0}
                                border={'1px solid #000000'}
                                rounded='0'
                                size="lg"
                                _focusVisible={
                                    {
                                        border: '1px solid #000000'
                                    }
                                }
                            />
                        </VStack>
                        <VStack h={'9%'} w={'100%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <FormLabel
                                fontSize={'lg'}
                                color={'#000000'}
                                h={'20%'}
                            >
                                Country / Region
                            </FormLabel>
                            <Input type='text'
                                borderRadius={0}
                                border={'1px solid #000000'}
                                placeholder='India'
                                _focusVisible={
                                    {
                                        border: '1px solid #000000'
                                    }
                                }
                                rounded='0'
                                size="lg"
                            />
                        </VStack>
                        <VStack h={'9%'} w={'100%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <FormLabel
                                fontSize={'lg'}
                                color={'#000000'}
                                h={'20%'}
                            >
                                Street Address
                            </FormLabel>
                            <Input type='text'
                                borderRadius={0}
                                border={'1px solid #000000'}
                                rounded='0'
                                size="lg"
                                _focusVisible={
                                    {
                                        border: '1px solid #000000'
                                    }
                                }
                            />
                        </VStack>
                        <VStack h={'9%'} w={'100%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <FormLabel
                                fontSize={'lg'}
                                color={'#000000'}
                                h={'20%'}
                            >
                                Town / City
                            </FormLabel>
                            <Input type='text'
                                borderRadius={0}
                                border={'1px solid #000000'}
                                rounded='0'
                                size="lg"
                                _focusVisible={
                                    {
                                        border: '1px solid #000000'
                                    }
                                }
                            />
                        </VStack>
                        <VStack h={'9%'} w={'100%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <FormLabel
                                fontSize={'lg'}
                                color={'#000000'}
                                h={'20%'}
                            >
                                Provience
                            </FormLabel>
                            <Input type='text'
                                borderRadius={0}
                                border={'1px solid #000000'}
                                rounded='0'
                                size="lg"
                                _focusVisible={
                                    {
                                        border: '1px solid #000000'
                                    }
                                }
                                placeholder='western provience'
                            />
                        </VStack>
                        <VStack h={'9%'} w={'100%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <FormLabel
                                fontSize={'lg'}
                                color={'#000000'}
                                h={'20%'}
                            >
                                Zip Code
                            </FormLabel>
                            <Input type='text'
                                borderRadius={0}
                                border={'1px solid #000000'}
                                rounded='0'
                                size="lg"
                                _focusVisible={
                                    {
                                        border: '1px solid #000000'
                                    }
                                }
                            />
                        </VStack>
                        <VStack h={'9%'} w={'100%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <FormLabel
                                fontSize={'lg'}
                                color={'#000000'}
                                h={'20%'}
                            >
                                Phone
                            </FormLabel>
                            <Input type='number'
                                borderRadius={0}
                                border={'1px solid #000000'}
                                rounded='0'
                                size="lg"
                                _focusVisible={
                                    {
                                        border: '1px solid #000000'
                                    }
                                }
                            />
                        </VStack>
                        <VStack h={'9%'} w={'100%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}>
                            <Input type='text'
                                borderRadius={0}
                                border={'1px solid #000000'}
                                _focusVisible={
                                    {
                                        border: '1px solid #000000'
                                    }
                                }
                                rounded='0'
                                size="lg"
                                placeholder='Additional information'
                            />
                        </VStack>
                    </Flex>
                    <Flex
                        h={'100%'}
                        w={'47%'}
                        justifyContent={'flex-start'}
                        alignItems={'flex-start'}
                    >
                        <VStack h={'55%'}
                            w={'100%'}
                            justifyContent={'flex-start'}
                            gap={'4'}
                            alignItems={'flex-start'}
                        >
                            <VStack h={'35%'}
                                w={'100%'}
                                justifyContent={'center'}
                                alignContent={'center'}
                                gap={'2'}
                                borderBottom={'1px solid #D9D9D9'}
                            >
                                <Flex
                                    w={'100%'}

                                    justifyContent={'space-between'} alignItems={'center'}
                                >
                                    <Text fontSize={'x-large'}
                                        color={'#000000'}
                                        fontWeight={600}
                                    >Product</Text>
                                    <Text
                                        fontSize={'x-large'}
                                        color={'#000000'}
                                        fontWeight={600}
                                    >Subtotal</Text>
                                </Flex>
                                <Flex
                                    w={'100%'}
                                    justifyContent={'space-between'} alignItems={'center'}>
                                    <Text
                                        fontSize={'xl'}
                                        color={'#000000'}
                                        fontWeight={600}
                                    >Lorem Ipsum</Text>
                                    <Text
                                        fontSize={'xl'}
                                        color={'#000000'}
                                        fontWeight={600}
                                    >Rs. 250,000.00</Text>
                                </Flex>
                                <Flex
                                    w={'100%'}
                                    justifyContent={'space-between'} alignItems={'center'}>
                                    <Text
                                        fontSize={'xl'}
                                        color={'#000000'}
                                        fontWeight={600}
                                    >Subtotal</Text>
                                    <Text
                                        color={'#000000'}
                                        fontSize={'xl'}
                                        fontWeight={600}
                                    >Rs. 250,000.00</Text>
                                </Flex>
                                <Flex
                                    w={'100%'}
                                    justifyContent={'space-between'} alignItems={'center'}>
                                    <Text
                                        fontSize={'xl'}
                                        color={'#000000'}
                                        fontWeight={600}
                                    >Total</Text>
                                    <Text
                                        color={'#B88E2F'}
                                        fontSize={'xl'}
                                        fontWeight={600}
                                    >Rs. 250,000.00</Text>
                                </Flex>
                            </VStack>
                            <VStack
                                h={'65%'}
                                w={'100%'}
                                justifyContent={'space-between'}
                                alignItems={'flex-start'}
                            >
                                <RadioGroup h={'70%'}
                                    w={'100%'}
                                    onChange={setPaymentMethod} value={paymentMethod}>
                                    <VStack align="start" gap={3}>
                                        <Radio value="bank"
                                        >
                                            <Text color={'#000000'}
                                                fontWeight={600}>
                                                Direct Bank Transfer
                                            </Text>
                                        </Radio>
                                        <Text color={'#000000'} fontSize={'lg'}
                                            fontWeight={600}
                                        >
                                            Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.
                                        </Text>
                                        <Radio value="card"
                                        >
                                            <Text color={'#000000'}
                                                fontWeight={600}
                                            >
                                                Direct Bank Transfer
                                            </Text>
                                        </Radio>
                                        <Radio value="cod"
                                            color={'#000000'}
                                        >
                                            <Text color={'#000000'}
                                                fontWeight={600}
                                            >
                                                Cash on Delivery
                                            </Text>
                                        </Radio>

                                        <Text color={'#000000'} fontSize={'lg'}
                                            fontWeight={600}
                                        >
                                            Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.
                                        </Text>
                                    </VStack>
                                </RadioGroup>
                                <OutlinedButton rounded='0' type='submit' width='180px' color={'#054E47'}
                                    _hover={
                                        { bg: 'none' }
                                    }
                                >
                                    PLACE ORDER
                                </OutlinedButton>
                            </VStack>
                        </VStack>
                    </Flex>
                </HStack>
                <PlaceSale />
            </VStack>
        </>
    )
}




