import React from 'react'
import { Container, Card, Text, VStack } from "@chakra-ui/react"

export default function PrivacyPolicy() {
    return (
        <Container maxW='8xl' my='6'>
            <VStack w='100%' spacing='4'>
                <Card p='5' w='100%'>
                    <Text fontSize='2xl' fontWeight='800' className='customfont'>Privacy Policy</Text>
                </Card>
                <Card p='8' w='100%'>

                    <strong>Privacy Policy</strong> 


                </Card>
            </VStack>
        </Container>
    )
}
